<template>
  <div class="order">
    <div class="orderHead">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1" v-show="role_order_pre">未处理订单({{ untreated }})</el-menu-item>
        <el-menu-item index="2" v-show="role_order_gue">客诉订单({{ customerOrder }})</el-menu-item>
        <el-menu-item index="3" v-show="role_order_two">二次确认({{ secondaryConfirm }})</el-menu-item>
        <el-menu-item index="4" v-show="role_order_all">全部</el-menu-item>
        <el-menu-item index="5">艺龙超时警告{{ elongConfirm }}</el-menu-item>
        <el-menu-item index="6">艺龙Kpi</el-menu-item>
        <!-- <el-menu-item index="5">猩房房订单</el-menu-item> -->
      </el-menu>
    </div>
    <div class="orderSearchBox" v-if="activeIndex != 6">
      <div class="paddingtop20" v-if="activeIndex == 2">
        <span class="marginRight14 relativeself">事件状态</span>

        <el-checkbox-button :indeterminate="isIndeterEventStatus" v-model="isAllEventStatus"
          class="marginright20 relativeself" size="mini" @change="checkAllEventStatus">全选</el-checkbox-button>
        <el-checkbox-group style="display: inline-block" size="mini" v-model="eventStatusValue"
          @change="changeEventStatus">
          <el-checkbox-button class="marginright20" v-for="item in eventStatusOptian" :label="item.value"
            :key="item.value">{{ item.label }}</el-checkbox-button>
        </el-checkbox-group>
        <el-checkbox-group style="display: inline-block" size="mini" v-model="myAboutValue" @change="changeMyAbout">
          <el-checkbox-button class="marginright20" v-for="item in myAboutOption" :label="item.value"
            :key="item.value">{{ item.label }}</el-checkbox-button>
        </el-checkbox-group>
      </div>

      <div class="paddingtop20" v-if="activeIndex == 2">
        <span class="marginRight14 relativeself">事件类型</span>
        <el-checkbox-button :indeterminate="isIndeterEventType" v-model="isAllEventType"
          class="marginright20 relativeself" size="mini" @change="checkAllEventType">全选</el-checkbox-button>
        <el-checkbox-group style="display: inline-block" size="mini" v-model="eventTypeValue" @change="changeEventType">
          <el-checkbox-button class="marginright20" v-for="item in eventTypeOptian" :label="item.value"
            :key="item.value">{{ item.label }}</el-checkbox-button>
        </el-checkbox-group>
      </div>
      <div class="paddingtop20" v-if="activeIndex == 4">
        <span class="marginRight14">快速筛选</span>

        <el-radio-group size="mini" @change="seachFn" v-model="searchData.quickSelected">
          <el-radio-button style="margin-right: 20px" label="1">今日新订{{ todayNewOrder }}</el-radio-button>
          <el-radio-button style="margin-right: 20px" label="2">今日入住{{ todayNewComeIn }}</el-radio-button>
          <el-radio-button label="3">待入住{{ etcComeIn }}</el-radio-button>
        </el-radio-group>
      </div>

      <div class="paddingtop20">
        <span class="marginRight14">客人姓名</span>
        <el-input size="mini" class="inlinebolckBox" style="width: 120px" v-model="searchData.guestName"></el-input>
        <span class="marginRight14">订单号</span>
        <el-input size="mini" class="inlinebolckBox" style="width: 140px" v-model="searchData.orderSn"
          placeholder="渠道订单号或后四位"></el-input>
        <span class="marginRight14">第三方订单号</span>
        <el-input size="mini" class="inlinebolckBox" style="width: 140px" v-model="searchData.agentOrderSn"
          placeholder="完整单号或后四位"></el-input>
        <span class="marginRight14">城市</span>
        <el-input size="mini" class="inlinebolckBox" style="width: 90px" v-model="searchData.cityName"
          placeholder="城市名"></el-input>
        <span class="marginRight14">酒店名称</span>
        <el-input size="mini" class="inlinebolckBox" style="width: 140px" v-model="searchData.hotelName"
          placeholder></el-input>
      </div>
      <div class="paddingtop20">
        <span class="marginRight14">日期选择</span>
        <el-select size="mini" v-model="searchData.timeType" class="inlinebolckBox"
          style="width: 100px; margin-right: 20px">
          <el-option label="全部日期" value="1"></el-option>
          <el-option label="下单日期" value="2"></el-option>
          <el-option label="入住日期" value="3"></el-option>
          <el-option label="离店日期" value="4"></el-option>
        </el-select>
        <el-date-picker v-model="searchData.startTime" size="mini" class="inlinebolckBox"
          style="width: 140px; margin-right: 9px" type="date" placeholder="选择日期" format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"></el-date-picker>
        <span>至</span>
        <el-date-picker v-model="searchData.endTime" size="mini" class="inlinebolckBox"
          style="width: 140px; margin-left: 9px" type="date" placeholder="选择日期" format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"></el-date-picker>
        <span class="marginRight14">发单状态</span>
        <el-select size="mini" v-model="searchData.status" class="inlinebolckBox" style="width: 120px">
          <el-option v-for="(item, index) in statusList" :key="index" :label="item.label"
            :value="item.value"></el-option>
        </el-select>
        <span class="marginRight14">订单类型</span>
        <el-select size="mini" v-model="searchData.orderType" class="inlinebolckBox" style="width: 110px">
          <el-option v-for="(item, index) in orderTypeList" :key="index" :label="item.label"
            :value="item.value"></el-option>
        </el-select>
        <span class="marginRight14">支付状态</span>
        <el-select size="mini" v-model="searchData.userIsPay" class="inlinebolckBox"
          style="width: 110px; margin-right: 0px">
          <el-option label="未支付" value="0"></el-option>
          <el-option label="已支付" value="1"></el-option>
        </el-select>
      </div>
      <div class="paddingtop20" style="margin-top: 10px">
        <el-button size="mini" type="primary" @click="seachFn" style="margin-right: 26px">搜索</el-button>
        <el-button size="mini" type="primary" @click="cleanSearchData" plain style="margin-right: 26px">清空</el-button>
        <el-button size="mini" type="primary" @click="gotoManuaOrder" plain v-if="activeIndex != 5"
          style="margin-right: 26px">手工录入订单</el-button>
        <el-button size="mini" type="primary" @click="gotoReleaseOrder" plain v-if="activeIndex == 5"
          style="margin-right: 26px">发布订单</el-button>
        <el-button size="mini" type="primary" plain>
          <a :href="excelUrl" style="color: #256ef1" @click="orderExport">导出订单</a>
        </el-button>
        <!-- <a  style="margin-right: 26px"  :href="excelUrl" @click="orderExport"
          >导出订单</a
        >-->
      </div>
    </div>

    <div class="orderContentBigBox" v-if="activeIndex != 6">
      <div class="searchBoxTwo">
        <el-row>
          <el-col :span="16">
            <span class="marginright20">渠道:</span>
            <el-button type="text" @click="setSearchPlatform('')">全部({{ allChannelCount }})</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" @click="setSearchPlatform(5)">携程({{ ctripChannelCount }})</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" @click="setSearchPlatform(4)">去哪儿({{ qunarChannelCount }})</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" @click="setSearchPlatform(3)">美团({{ meiTuanChannelCount }})</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" @click="setSearchPlatform(2)">飞猪({{ filPigChannelCount }})</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" @click="setSearchPlatform(1)">艺龙({{ sameCityChannelCount }})</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" @click="setSearchPlatform(6)">百度({{ baiduChannelCount }})</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" @click="setSearchPlatform(7)">萝卜({{ luoboChannelCount }})</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" @click="setSearchPlatform(8)">房仓天下({{ fctxChannelCount }})</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button type="text" @click="setSearchPlatform(0)">手工({{ manualChannelCount }})</el-button>
          </el-col>
          <el-col :span="16" v-if="activeIndex == 5">
            <span class="xingfangfang">艺龙超时警告</span>
          </el-col>
          <el-col :span="8" class="rightcolBox">
            <span class="marginright10" style="color: #999999">共找到{{ total }}条记录</span>
            <span class="marginright10" style="color: #333333">每页显示:</span>
            <span class="pointer" @click="setPageFn(20)" :class="setPage == 20 ? 'checkedPageText' : ''">20条</span>

            <el-divider direction="vertical"></el-divider>
            <span class="pointer" @click="setPageFn(50)" :class="setPage == 50 ? 'checkedPageText' : ''">50条</span>

            <el-divider direction="vertical"></el-divider>
            <span class="pointer" @click="setPageFn(100)" :class="setPage == 100 ? 'checkedPageText' : ''">100条</span>

            <el-divider direction="vertical"></el-divider>
            <span class="pointer" @click="setPageFn(200)" :class="setPage == 200 ? 'checkedPageText' : ''">200条</span>
          </el-col>
        </el-row>
        <el-row v-if="activeIndex != 5">
          <el-col :span="10" class="rightcolBox" style="text-align: left">
            <el-button size="mini" type="primary" @click="setOrderType('')" style="margin-right: 26px">全部({{
        allTypeOrder }})</el-button>
            <el-button size="mini" type="primary" plain v-if="activeIndex != 2" @click="setOrderType(1)"
              style="margin-right: 26px">催单({{ cuiTypeOrder }})</el-button>
            <el-button size="mini" type="primary" plain v-if="activeIndex != 2" @click="setOrderType(2)"
              style="margin-right: 26px">交接单({{ jiaoTypeOrder }})</el-button>
            <el-button size="mini" type="primary" plain v-if="activeIndex != 2" @click="setOrderType(3)"
              style="margin-right: 26px">拦截单({{ lanTypeOrder }})</el-button>
            <!-- <el-button
              size="mini"
              type="primary"
              plain
              v-if="activeIndex == 2"
              @click="setOrderType(5)"
              style="margin-right: 26px"
              >客诉({{ kesuTypeOrder }})</el-button
            >-->
            <el-button size="mini" type="primary" plain v-if="activeIndex == 2" @click="setOrderType(55)"
              style="margin-right: 26px">取消({{ kesuFiveTypeOrder }})</el-button>
            <el-button size="mini" type="primary" plain v-if="activeIndex == 2" @click="setOrderType(4)"
              style="margin-right: 26px">二次确认({{ confirmationTypeOrder }})</el-button>
          </el-col>
          <el-col :span="14" class="rightcolBox">
            <span class="marginright10">职位</span>
            <el-select size="mini" @change="getOperationsAssistantGroup" class="marginright20" style="width: 90px"
              v-model="searchData.roleName">
              <el-option v-for="(item, index) in roleNameList" :key="index" :label="item.label"
                :value="item.value"></el-option>
            </el-select>

            <span class="marginright10">组别</span>
            <el-select size="mini" class="marginright20" style="width: 90px" placeholder="全部"
              v-model="searchData.goupId">
              <el-option v-for="(item, index) in groupRoleList" :key="index" :label="item.groupName"
                :value="item.groupId"></el-option>
            </el-select>
            <span class="marginright10">人员</span>
            <el-input size="mini" class="marginright20" style="width: 100px" v-model="searchData.people"></el-input>
            <el-button size="mini" type="success" @click="seachFn" class="marginright10">查询</el-button>
            <el-button size="mini" type="primary" @click="cleanSearchDataT" plain class="marginright10">清空</el-button>

            <el-button size="mini" type="info" @click="openChangeOrderTypeDiab">批量转至</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="orderOneTableBox">
        <el-table ref="tableone" :data="tableData" v-show="activeIndex != 2" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50"></el-table-column>

          <el-table-column label="订单号/城市/酒店" width="140">
            <template slot-scope="scope">
              <el-popover placement="top-start" title="酒店电话" width="200" trigger="hover"
                :content="scope.row.tel ? scope.row.tel : '未记录'">
                <div slot="reference">
                  {{ scope.row.orderSn }}
                  <div v-if="scope.row.orderType == 1" class="cuidanBox" style="background: #f7a12e">催单</div>
                  <div v-else-if="scope.row.orderType == 2" class="cuidanBox" style="background: #ee4d52">交接单</div>
                  <div v-else-if="scope.row.orderType == 3" class="cuidanBox" style="background: #38455d">拦截订单</div>
                  <div v-show="activeIndex == 4" v-else-if="scope.row.orderType == 4" class="cuidanBox"
                    style="background: #5473e8">二次确认</div>
                  <div v-show="activeIndex == 4" v-else-if="scope.row.orderType == 5" class="cuidanBox"
                    style="background: #ee4d52">客诉订单</div>
                  <div v-show="activeIndex == 4" v-else-if="scope.row.orderType == 6" class="cuidanBox"
                    style="background: #38455d">服务申诉</div>
                  {{ scope.row.orderAddress }}
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="入离时间" width="100">
            <template slot-scope="scope">
              <div>
                <div>{{ scope.row.inAndOutTime }}</div>
                <div style="color: #999999">{{ scope.row.fewNights }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="房型/间数" width="120">
            <template slot-scope="scope">
              <div>
                <div @click="gotoRoom(scope.row)" style="color: #0099cc; cursor: pointer">{{ scope.row.roomTypeAndSum }}
                </div>
                <div style="color: #999999">{{ scope.row.fewRoom }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="入住人" width="80">
            <template slot-scope="scope">
              <div>
                <div>{{ scope.row.inManAndSum }}</div>
                <div style="color: #999999">{{ scope.row.fewMan }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="noticeTime" label="通知时间" width="90"></el-table-column>
          <el-table-column prop="channel" label="分销方-渠道供应方" width="100">
            <template slot-scope="scope">
              <div>
                <div style="
                    height: 48px;
                    line-height: 24px;
                    text-align: center;
                    border-bottom: 1px solid #ebf1fa;
                  ">{{ scope.row.channel }}</div>

                <div style="
                    min-height: 48px;
                    line-height: 48px;
                    text-align: center;
                    color: #0099cc;
                  ">酒店</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="渠道订单号/发单号" width="120">
            <template slot-scope="scope">
              <div>
                <div style="
                    height: 48px;
                    text-align: center;
                    border-bottom: 1px solid #ebf1fa;
                  ">
                  {{ scope.row.channelOrder }}
                  <div class="isReservedBox" v-if="scope.row.isReserved == 1">保留房</div>
                  <div class="isReservedBox" v-if="scope.row.isReserved == 1">立即确认</div>
                  <div class="isReservedBox" v-if="scope.row.isReserved == 1">自动接单</div>
                  <div class="isReservedBox" style="background: #919aa9" v-if="scope.row.isCancelOrder">取消</div>
                </div>

                <div style="
                    min-height: 48px;
                    line-height: 48px;
                    text-align: center;
                    color: #0099cc;
                  ">
                  {{ scope.row.orderHashcode }}
                  <span v-if="scope.row.billType == 2">{{ scope.row.hotelState | formatHotelState }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="总底价/总采购价/业绩" width="100">
            <template slot-scope="scope">
              <div>
                <div style="
                    height: 48px;
                    line-height: 48px;
                    text-align: center;
                    border-bottom: 1px solid #ebf1fa;
                  ">
                  <span v-if="scope.row.totalAfterTax" style="color: #f7a12e">
                    <span style="margin-right: 5px">CNY</span>
                    {{ scope.row.totalAfterTax }}
                  </span>
                </div>

                <div style="
                    min-height: 48px;
                    line-height: 24px;
                    text-align: center;
                  ">
                  <span v-if="scope.row.purchasePrice" style="color: #25a1d0">
                    <span style="margin-right: 5px">CNY</span>
                    {{ scope.row.purchasePrice }}
                  </span>
                  <br />
                  <span v-if="scope.row.profitPrice" style="color: #ee4d52">
                    <span style="margin-right: 5px">+￥</span>
                    {{ scope.row.profitPrice }}
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="订单/支付状态" width="110">
            <template slot-scope="scope">
              <div>
                <div style="
                    height: 48px;
                    text-align: center;
                    border-bottom: 1px solid #ebf1fa;
                  ">
                  {{ scope.row.status | formatStatus }}/{{
        scope.row.userIsPay | formatUserIsPay
      }}
                  <span style="color: #ee4d52">
                    {{ scope.row.orderStatusCountDown }}
                    <!-- | formatOrderStatusCountDown -->
                  </span>
                  <span style="color: #ee4d52" v-if="scope.row.iscd == 1">已催单</span>
                </div>

                <div :style="scope.row.colorType" style="
                    min-height: 48px;
                    line-height: 48px;
                    text-align: center;
                  ">{{ scope.row.isHotelPay | formatIsHotelPay }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="handler" label="经/处/时/态" width="70">
            <template slot-scope="scope">
              <div>{{ scope.row.productManagerName }}</div>
              <div>{{ scope.row.handler }}</div>
              <div v-if="scope.row.isConfirm == 0 ||
        scope.row.isConfirm == 1 ||
        scope.row.isConfirm == 2
        ">{{ scope.row.isConfirm | formatIsConfim }}</div>
              <div v-if="scope.row.confirmTime && scope.row.isConfirm == 0" style="color: #ee4d52">{{
        scope.row.confirmTime }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="120">
            <template slot-scope="scope">
              <div style="color: #256ef1">
                <div style="
                    height: 48px;
                    text-align: center;
                    border-bottom: 1px solid #ebf1fa;
                  ">
                  <el-row>
                    <el-col :span="12" v-if="scope.row.status == '1'">
                      <div class="jieshouandtongyi" @click="openConfirm(scope.row.orderId)"
                        style="width: 36px; margin-left: 12px">确认号</div>
                    </el-col>
                    <el-col :span="12" v-if="scope.row.status == '0' || scope.row.status == '9'">
                      <div class="jieshouandtongyi" @click="openOrderAccept(scope.row.orderId, scope.row.status)"
                        style="width: 36px; margin-left: 12px">接受</div>
                    </el-col>

                    <el-col :span="12" v-if="scope.row.status == '0' || scope.row.status == '9'">
                      <div class="jujueBox" @click="setOrderRefuse(scope.row.orderId)">拒绝</div>
                    </el-col>

                    <el-col :span="12" v-if="scope.row.status == '2' || scope.row.status == '3'">
                      <div @click="setAgreeCancel(scope.row)" class="jieshouandtongyi" style="margin-top: 4px">同意取消
                      </div>
                    </el-col>
                    <el-col :span="12" v-if="scope.row.status == '3'">
                      <div @click="setUnAgreeCancel(scope.row.orderId)" class="jieshouandtongyi"
                        style="margin-top: 4px">拒绝取消</div>
                    </el-col>
                    <el-col :span="12" v-if="scope.row.isCancelOrder != 'true'">
                      <span @click="openOrderLogDia(scope.row.orderId)" style="cursor: pointer">备注</span>
                    </el-col>
                    <el-col :span="12">
                      <div>
                        <span @click="openChangeOrderTypeDia(scope.row.orderId)" style="cursor: pointer">转至</span>
                      </div>
                    </el-col>
                  </el-row>
                </div>

                <div style="
                    min-height: 48px;
                    line-height: 24px;
                    text-align: center;
                  ">
                  <el-row>
                    <el-col :span="12">
                      <span @click="openOrderInfoDia(scope.row)" style="cursor: pointer">发单</span>
                    </el-col>
                    <el-col :span="12" v-if="scope.row.isLock == 1">
                      <span @click="setOrderLock(scope.row.orderId, 0)" style="cursor: pointer">解锁</span>
                    </el-col>
                    <el-col :span="12" v-if="scope.row.isCancelOrder == 'true'">
                      <span @click="openOrderLogDia(scope.row.orderId)" style="cursor: pointer">备注</span>
                    </el-col>

                    <el-col :span="12">
                      <span style="cursor: pointer" @click="openOrderDetailInfoDia(scope.row.orderId)">详情</span>
                    </el-col>
                    <el-col :span="12" v-if="scope.row.status == '8'" class="jieshouandtongyi" style="margin-top: 4px">
                      <span @click="setOrderAccept(scope.row.orderId)">我知道了</span>
                    </el-col>
                    <el-col :span="12" v-if="scope.row.isLock != 1">
                      <span @click="setOrderLock(scope.row.orderId, 1)" style="cursor: pointer">锁定</span>
                    </el-col>
                    <el-col :span="12" v-if="activeIndex == 3 && scope.row.isInterceptState == 0">
                      <span @click="openOrderIsInterceptStateDia(scope.row.orderId)" style="cursor: pointer">确认</span>
                    </el-col>
                    <el-col :span="12" v-if="activeIndex != 5">
                      <span @click="openSetProfitPrice(scope.row)" style="cursor: pointer">修改业绩</span>
                    </el-col>
                    <el-col :span="12" v-if="scope.row.isConfirm == 0 &&
        (role.includes('market_baofang_zg') == true ||
          role.includes('market_baofang_a') == true ||
          role.includes('market_jl') == true ||
          role.includes('all') == true)
        ">
                      <div>
                        <span @click="setIsConfirm(scope.row.orderId)" style="cursor: pointer">收到</span>
                      </div>
                    </el-col>
                    <el-col :span="12" v-if="activeIndex != 5">
                      <span @click="openSetOrderState(scope.row)" style="cursor: pointer">修改状态</span>
                    </el-col>
                    <el-col :span="12" v-if="scope.row.platform == 6">
                      <span @click="openManualRefundDia(scope.row)" style="cursor: pointer">手动退款</span>
                    </el-col>
                    <el-col :span="12" v-if="activeIndex == 5">
                      <span @click="openCancelPartForXffOrder(scope.row)" style="cursor: pointer">部分取消</span>
                    </el-col>
                    <el-col :span="12" v-if="activeIndex == 5">
                      <span @click="cancelAllForXff(scope.row)" style="cursor: pointer">全部取消</span>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-table ref="tabletwo" :data="eventTableData" @selection-change="handleSelectionChange"
          v-show="activeIndex == 2">
          <el-table-column type="selection" width="50"></el-table-column>

          <el-table-column label="订单号/城市/酒店" width="140">
            <template slot-scope="scope">
              <el-popover placement="top-start" title="酒店电话" width="200" trigger="hover"
                :content="scope.row.tel ? scope.row.tel : '未记录'">
                <div slot="reference">
                  {{ scope.row.orderSn }}
                  {{ scope.row.orderAddress }}
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="evenType" label="类型" width="100" :formatter="formatEvetType"></el-table-column>
          <el-table-column prop="status" label="处理状态" width="100">
            <template slot-scope="scope">
              <div>
                <div v-if="scope.row.status == 0" class="eventStatusBox" style="background: #ee4d52">未处理</div>
                <div v-if="scope.row.status == 1" class="eventStatusBox" style="background: #f7a12e">跟进中</div>
                <div v-if="scope.row.status == 2" class="eventStatusBox" style="background: #33b93f">已完成</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="90"></el-table-column>
          <el-table-column prop="handler" label="处理人/来源" width="100"></el-table-column>
          <el-table-column prop="eventInfo" label="事件内容" width="172"></el-table-column>
          <el-table-column label="入离时间" width="100">
            <template slot-scope="scope">
              <div>
                <div>{{ scope.row.inAndOutTime }}</div>
                <div style="color: #999999">{{ scope.row.fewNights }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="房型/间数" width="120">
            <template slot-scope="scope">
              <div>
                <div @click="gotoRoom(scope.row)" style="color: #0099cc; cursor: pointer">{{ scope.row.roomTypeAndSum }}
                </div>
                <div style="color: #999999">{{ scope.row.fewRoom }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="入住人" width="80">
            <template slot-scope="scope">
              <div>
                <div>{{ scope.row.inManAndSum }}</div>
                <div style="color: #999999">{{ scope.row.fewMan }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-table :data="props.row.listOrderLogVo" style="margin: 20px">
                <el-table-column prop="logId" label="序号" width="180"></el-table-column>
                <el-table-column prop="managerName" label="处理人" width="180"></el-table-column>
                <el-table-column prop="operationTime" label="操作时间" width="180"></el-table-column>
                <el-table-column prop="remarks" label="备注"></el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <div style="color: #256ef1">
                <div style="cursor: pointer" v-if="scope.row.status == 0" class="eventEditBtn">
                  <span @click="setEventTrail(scope.row.orderId)">跟进</span>
                </div>
                <div style="cursor: pointer" v-if="scope.row.status == 1" class="eventEditBtn">
                  <span @click="openEventOverDia(scope.row)">回复</span>
                </div>
                <div class="eventEditBtnTwo" v-if="scope.row.status != 2">
                  <span style="cursor: pointer" @click="setEventPerson(scope.row.orderId)">转人</span>
                </div>
                <div class="eventEditBtnTwo">
                  <span @click="openOrderLogDia(scope.row.orderId)" style="cursor: pointer">备注</span>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination v-if="total > searchData.rows" class="roomPagin" background small
          @current-change="handleCurrentChange" :page-size="searchData.rows" layout="prev, pager, next"
          :total="total"></el-pagination>
      </div>
    </div>
    <div v-if="activeIndex==6">
      <Elongkpi ref="elongkpi"></Elongkpi>

    </div>


    <AddOrderLog ref="addorderlog"></AddOrderLog>
    <OrderInfo ref="orderinfo"></OrderInfo>
    <ChangeOrderTypeDia ref="changeordertypedia"></ChangeOrderTypeDia>
    <ChangeEventPerson ref="changeeventperson"></ChangeEventPerson>
    <EventOver ref="eventover"></EventOver>
    <InterceptStateDia ref="interceptstatedia"></InterceptStateDia>
    <ConfirmDia ref="confirmdia"></ConfirmDia>
    <OrderAccept ref="orderaccept"></OrderAccept>
    <SetProfitPrice ref="setprofitPrice"></SetProfitPrice>
    <SetOrderState ref="setorderstate"></SetOrderState>
    <CancelPartForXff ref="cancelpartforxff"></CancelPartForXff>
    <Agreebaiducancel ref="agreebaiducancel"></Agreebaiducancel>
    <Manualrefund ref="manualrefund"></Manualrefund>
  </div>
</template>

<script>
import local from "@/utils/local.js";
import { bannerStatistics_api } from "../../apis/order";
import { getOperationsAssistantGroup_api } from "../../apis/order";
import { pageOrderListPre_api } from "../../apis/order";
import { pageOrderListGuest_api } from "../../apis/order";
import { getChannel_api } from "../../apis/order";
import { getOrderType_api } from "../../apis/order";
import { orderLock_api } from "../../apis/order";
import { orderAccept_api } from "../../apis/order";
import { agreeCancel_api } from "../../apis/order";
import { notAgreeCancel_api } from "../../apis/order";
import { orderRefuse_api } from "../../apis/order";
import { pageOrderListConfirm_api } from "../../apis/order";
import { pageOrderListConfirmSelected_api } from "../../apis/order";
import { eventTrail_api, setIsConfirm_api, pageOrderListPreElong_api } from "../../apis/order";
import { cancelAllForXff_api } from "../../apis/order";

import AddOrderLog from "../../components/dialog/order/AddOrderLog.vue";
import OrderInfo from "../../components/dialog/order/OrderInfo.vue";
import ChangeOrderTypeDia from "../../components/dialog/order/ChangeOrderTypeDia.vue";
import ChangeEventPerson from "../../components/dialog/order/ChangeEventPerson.vue";
import EventOver from "../../components/dialog/order/EventOver.vue";
import InterceptStateDia from "../../components/dialog/order/InterceptStateDia.vue";
import ConfirmDia from "../../components/dialog/order/ConfirmDia.vue";
import OrderAccept from "../../components/dialog/order/OrderAccept.vue";
import { getRoleKey } from "../../utils/rolekey";
import SetProfitPrice from "../../components/dialog/finance/SetProfitPrice.vue";
import SetOrderState from "../../components/dialog/order/SetOrderState.vue";
import CancelPartForXff from "../../components/dialog/order/CancelPartForXff.vue";
import Agreebaiducancel from "../../components/dialog/order/Agreebaiducancel.vue";
import Manualrefund from "../../components/dialog/order/Manualrefund.vue";
import Elongkpi from "../../components/dialog/order/Elongkpi.vue"

export default {
  components: {
    AddOrderLog,
    OrderInfo,
    ChangeOrderTypeDia,
    ChangeEventPerson,
    EventOver,
    InterceptStateDia,
    ConfirmDia,
    OrderAccept,
    SetProfitPrice,
    SetOrderState,
    CancelPartForXff,
    Agreebaiducancel,
    Manualrefund,
    Elongkpi
  },

  data() {
    return {
      role_order_pre: getRoleKey("role_order_pre"),
      role_order_gue: getRoleKey("role_order_gue"),
      role_order_two: getRoleKey("role_order_two"),
      role_order_all: getRoleKey("role_order_all"),

      role: JSON.stringify(local.get("role")), //角色

      market_baofang_zg: getRoleKey("market_baofang_zg"),
      market_baofang_a: getRoleKey("market_baofang_a"),
      market_jl: getRoleKey("market_jl"),
      all: getRoleKey("all"),
      // role_hotel_export: getRoleKey("role_hotel_export"),

      activeIndex: "1",
      searchData: {
        page: 1,
        rows: 20,
        guestName: "",
        orderSn: "",
        agentOrderSn: "",
        cityName: "",
        hotelName: "",
        startTime: "",
        endTime: "",
        status: "",
        orderType: "",
        userIsPay: "",
        platform: "",
        orderTypeT: "",
        timeType: "1",
        roleName: "",
        goupId: "",
        orderType: "",
        isSecende: 2,
        quickSelected: "",
        myAbout: "",
        eventStatus: "",
        eventType: "",
        people: "",
      },

      total: "",
      allTypeOrder: 0,
      cuiTypeOrder: 0,
      jiaoTypeOrder: 0,
      lanTypeOrder: 0,
      kesuTypeOrder: 0,
      kesuFiveTypeOrder: 0,
      confirmationTypeOrder: 0,

      allChannelCount: 0,
      ctripChannelCount: 0,
      qunarChannelCount: 0,
      meiTuanChannelCount: 0,
      filPigChannelCount: 0,
      sameCityChannelCount: 0,
      manualChannelCount: 0,
      baiduChannelCount: 0,
      luoboChannelCount: 0,
      fctxChannelCount:0,
      statusList: [
        { label: "全部", value: "" },
        { label: "未处理", value: "0" },
        { label: "已确认", value: "1" },
        { label: "确认前取消中", value: "2" },
        { label: "确认后取消中", value: "3" },
        { label: "确认前取消", value: "4" },
        { label: "确认后取消", value: "5" },
        { label: "超时拒绝", value: "6" },
        { label: "正常拒绝", value: "7" },
        { label: "保留房确认中", value: "8" },
        { label: "订单超时", value: "9" },
        { label: "拒绝取消", value: "10" },
      ],
      orderTypeList: [
        { label: "全部", value: "" },
        { label: "正常订单", value: "0" },
        { label: "催单", value: "1" },
        { label: "交接单", value: "2" },
        { label: "拦截订单", value: "3" },
        { label: "二次确认", value: "4" },
        { label: "客诉订单", value: "5" },
        { label: "服务申诉", value: "6" },
      ],
      customerOrder: "0",
      untreated: "0",
      secondaryConfirm: "0",
      elongConfirm: "0",
      setPage: "20",
      roleNameList: [
        { label: "全部", value: "" },
        { label: "产品", value: "0" },
        { label: "运营", value: "1" },
      ],
      groupRoleList: [],
      tableData: [
        {
          orderAddress: 1,
          inAndOutTime: "1",
          roomTypeAndSum: "1",
          inManAndSum: "1",
          noticeTime: "1",
          channel: "1",
          channelOrder: "1",
          outstanding: "1",
          userIsPay: "1",
          handler: "1",
        },
      ],

      //客诉的事件状态
      eventStatusOptian: [
        { label: "未处理", value: 0 },
        { label: "跟进中", value: 1 },
        { label: "完成", value: 2 },
      ],
      myAboutOption: [
        { label: "我收到的", value: 100 },
        { label: "我发送的", value: 200 },
      ],
      eventStatusOptianValue: [0, 1, 2],
      eventStatusValue: [],
      myAboutValue: [],
      myAboutOptianValue: [100, 200],
      isIndeterEventStatus: true,
      isAllEventStatus: false,

      eventTypeOptian: [
        { label: "到店无房", value: 1 },
        { label: "到店查无", value: 2 },
        { label: "未到店查无", value: 3 },
        { label: "确认后满房", value: 4 },
        { label: "确认后涨价", value: 5 },
        { label: "协商取消", value: 6 },
      ],

      eventTypeOptianValue: [1, 2, 3, 4, 5, 6],
      eventTypeValue: [],
      isIndeterEventType: true,
      isAllEventType: false,
      eventTableData: [],

      //二次确认
      pageOrderConfirmList: [],

      todayNewOrder: 0,
      todayNewComeIn: 0,
      etcComeIn: 0,
      multipleSelection: [],
      timer: "",
      timerTwo: "",
      excelUrl: "",
      token: "",
    };
  },
  created() {
    this.token = local.get("tk");
    this.getBannerStatistics();
    this.timerTwo = setInterval(() => {
      this.getBannerStatistics();
    }, 10000);
    this.getChannel(1);
    this.getOrderType(1);
    this.getOperationsAssistantGroup();
    this.getPageOrderListConfirmSelected();

    // if (this.$route.query.activeIndex) {
    //   this.activeIndex = this.$route.query.activeIndex;
    //   this.searchData.quickSelected = this.$route.query.quickSelected;
    //   this.seachFn();
    // } else {
    //   this.seachFn();
    // }
    if (this.$route.query.activeIndex) {
      this.activeIndex = this.$route.query.activeIndex;
    }
    if (this.$route.query.quickSelected) {
      this.searchData.quickSelected = this.$route.query.quickSelected;
    }
    if (this.$route.query.orderSn) {
      this.searchData.orderSn = this.$route.query.orderSn;
    }
    this.seachFn();
  },
  filters: {
    formatStatus(val) {
      if (val == 0) {
        return "未处理";
      } else if (val == 1) {
        return "已确认";
      } else if (val == 2) {
        return "确认前取消中";
      } else if (val == 3) {
        return "确认后取消中";
      } else if (val == 4) {
        return "确认前取消";
      } else if (val == 5) {
        return "确认后取消";
      } else if (val == 6) {
        return "超时拒绝";
      } else if (val == 7) {
        return "正常拒绝";
      } else if (val == 8) {
        return "保留房确认中";
      } else if (val == 9) {
        return "订单超时";
      } else if (val == 10) {
        return "拒绝取消";
      }
    },
    formatUserIsPay(val) {
      if (val == 0) {
        return "未支付";
      } else if (val == 1) {
        return "已支付";
      }
    },
    formatIsHotelPay(val) {
      if (val == 0) {
        return "未打款";
      } else if (val == 1) {
        return "打款中";
      } else if (val == 2) {
        return "打款成功";
      }
    },
    formatIsConfim(val) {
      if (val == 0) {
        return "未收到";
      } else if (val == 1) {
        return "已收到";
      } else if (val == 2) {
        return "已超时";
      }
    },
    formatHotelState(val) {
      if (val == 0) {
        return "酒店未接单";
      } else if (val == 1) {
        return "酒店已接单";
      } else if (val == 2) {
        return "酒店拒绝接单";
      } else if (val == 3) {
        return "酒店取消未确认 ";
      } else if (val == 4) {
        return "酒店同意取消 ";
      } else if (val == 5) {
        return "酒店拒绝取消";
      }
    },
    //倒计时
    formatOrderStatusCountDown(val) {
      if (val == 0 || val == "" || val == null) {
        return "";
      } else if (val == "已超时") {
        // let value = Number(val);
        //let newTimeVal = new Date().getTime();
        return "已超时";
      } else {
        let newtime = new Date(val);
        let hour;
        if (newtime.getTime() > 3600000) {
          hour = parseInt(newtime.getTime() / 3600000);
          if (hour < 10) {
            hour = "0" + hour;
          }
        } else {
          hour = "00";
        }

        let minute;

        if (newtime.getTime() > 60000) {
          minute = newtime.getMinutes();
          if (minute < 10) {
            minute = "0" + minute;
          }
        } else {
          minute = "00";
        }

        let second = newtime.getSeconds();
        if (second < 10) {
          second = "0" + second;
        }

        return "还剩" + hour + ":" + minute + ":" + second;
      }
    },
  },
  mounted() {
    // let that = this;
  },
  beforeRouteLeave(to, from, next) {
    if (this.timer) {
      //如果定时器还在运行 或者直接关闭，不用判断
      clearInterval(this.timer); //关闭
    }
    if (this.timerTwo) {
      //如果定时器还在运行 或者直接关闭，不用判断
      clearInterval(this.timerTwo); //关闭
    }
    next();
  },
  methods: {
    //切换banner
    handleSelect(index) {
      this.activeIndex = index;
      if (this.activeIndex == 6) {
        this.$refs.elongkpi.searchFn()
      } else {
        this.searchData.orderTypeT = "";
        this.getPageOrderListConfirmSelected();
        this.seachFn();
      }

    },
    //banner统计
    async getBannerStatistics() {
      let { code, data } = await bannerStatistics_api();
      if (code == 0) {
        this.customerOrder = data.customerOrder;
        this.untreated = data.untreated;
        this.secondaryConfirm = data.secondaryConfirm;
        this.elongConfirm = data.elongConfirm;
      }
    },
    //选择每页条数
    setPageFn(val) {
      this.searchData.rows = val;
      this.setPage = val;
      if (this.activeIndex == 1) {
        this.getPageOrderListPre();
      } else if (this.activeIndex == 2) {
        this.getPageOrderListGuest();
      } else if (this.activeIndex == 3) {
        this.searchData.isSecende = 2;
        this.getPageOrderListConfirm();
      } else if (this.activeIndex == 4) {
        this.searchData.isSecende = 1;
        this.getPageOrderListConfirm();
      } else if (this.activeIndex == 5) {
        //  this.searchData.isSecende = 3;
        // this.getPageOrderListConfirm();
        this.getPageOrderListPreElong();
      }
    },
    //根据职位获取当前职位下的组别
    async getOperationsAssistantGroup() {
      let { code, data } = await getOperationsAssistantGroup_api({
        positionType: this.searchData.roleName,
      });
      if (code == 0) {
        this.groupRoleList = data;
      }
    },

    //订单/未处理列表
    async getPageOrderListPre() {
      let that = this;
      let checkedArr = JSON.parse(JSON.stringify(this.multipleSelection));
      this.multipleSelection = [];

      let { code, data } = await pageOrderListPre_api(this.searchData);
      if (code == 0) {
        data.records.forEach((item) => {
          if (item.isHotelPay == 0) {
            this.$set(item, "colorType", "color:#EE4D52");
          } else if (item.isHotelPay == 1) {
            this.$set(item, "colorType", "color:#33B93F");
          } else if (item.isHotelPay == 2) {
            this.$set(item, "colorType", "color:#256EF1");
          }
        });
        this.tableData = JSON.parse(JSON.stringify(data.records));

        if (this.timer) {
          //  console.log("摧毁计时器");
          clearInterval(this.timer);
        }

        this.timer = setInterval(() => {
          //  console.log("调用未处理计时器");

          //    console.log(checkedArr);
          let temporaryArr = JSON.parse(JSON.stringify(data.records));
          let yuanshiArr = JSON.parse(JSON.stringify(data.records));

          for (let i = 0; i < data.records.length; i++) {
            if (
              yuanshiArr[i].orderStatusCountDown == 0 ||
              yuanshiArr[i].orderStatusCountDown == "" ||
              yuanshiArr[i].orderStatusCountDown == null
            ) {
              temporaryArr[i].orderStatusCountDown = "";
            } else {
              if (
                Number(yuanshiArr[i].orderStatusCountDown) <
                new Date().getTime() ||
                Number(yuanshiArr[i].orderStatusCountDown) ==
                new Date().getTime()
              ) {
                temporaryArr[i].orderStatusCountDown = "已超时";
              } else {
                let val =
                  Number(yuanshiArr[i].orderStatusCountDown) -
                  new Date().getTime();
                let newtime = new Date(val);
                let hour;
                if (newtime.getTime() > 3600000) {
                  hour = parseInt(newtime.getTime() / 3600000);
                  if (hour < 10) {
                    hour = "0" + hour;
                  }
                } else {
                  hour = "00";
                }
                let minute;
                if (newtime.getTime() > 60000) {
                  minute = newtime.getMinutes();
                  if (minute < 10) {
                    minute = "0" + minute;
                  }
                } else {
                  minute = "00";
                }
                let second = newtime.getSeconds();
                if (second < 10) {
                  second = "0" + second;
                }

                temporaryArr[i].orderStatusCountDown =
                  "还剩" + hour + ":" + minute + ":" + second;
              }
            }

            if (yuanshiArr[i].confirmTime) {
              let val2 =
                Number(yuanshiArr[i].confirmTime) - new Date().getTime();
              let newtime2 = new Date(val2);
              let hour2;
              if (newtime2.getTime() > 3600000) {
                hour2 = parseInt(newtime2.getTime() / 3600000);
                if (hour2 < 10) {
                  hour2 = "0" + hour2;
                }
              } else {
                hour2 = "00";
              }
              let minute2;
              if (newtime2.getTime() > 60000) {
                minute2 = newtime2.getMinutes();
                if (minute2 < 10) {
                  minute2 = "0" + minute2;
                }
              } else {
                minute2 = "00";
              }
              let second2 = newtime2.getSeconds();
              if (second2 < 10) {
                second2 = "0" + second2;
              }

              temporaryArr[i].confirmTime =
                hour2 + ":" + minute2 + ":" + second2;
            }
          }
          for (let i = 0; i < temporaryArr.length; i++) {
            this.tableData[i].orderStatusCountDown =
              temporaryArr[i].orderStatusCountDown;
            this.tableData[i].confirmTime = temporaryArr[i].confirmTime;
          }
        }, 1000);

        this.total = data.total;
      }
    },

    async getPageOrderListPreElong() {
      let that = this;
      let checkedArr = JSON.parse(JSON.stringify(this.multipleSelection));
      this.multipleSelection = [];

      let { code, data } = await pageOrderListPreElong_api(this.searchData);
      if (code == 0) {
        data.records.forEach((item) => {
          if (item.isHotelPay == 0) {
            this.$set(item, "colorType", "color:#EE4D52");
          } else if (item.isHotelPay == 1) {
            this.$set(item, "colorType", "color:#33B93F");
          } else if (item.isHotelPay == 2) {
            this.$set(item, "colorType", "color:#256EF1");
          }
        });
        this.tableData = JSON.parse(JSON.stringify(data.records));

        if (this.timer) {
          //  console.log("摧毁计时器");
          clearInterval(this.timer);
        }

        this.timer = setInterval(() => {
          //  console.log("调用未处理计时器");

          //    console.log(checkedArr);
          let temporaryArr = JSON.parse(JSON.stringify(data.records));
          let yuanshiArr = JSON.parse(JSON.stringify(data.records));

          for (let i = 0; i < data.records.length; i++) {
            if (
              yuanshiArr[i].orderStatusCountDown == 0 ||
              yuanshiArr[i].orderStatusCountDown == "" ||
              yuanshiArr[i].orderStatusCountDown == null
            ) {
              temporaryArr[i].orderStatusCountDown = "";
            } else {
              if (
                Number(yuanshiArr[i].orderStatusCountDown) <
                new Date().getTime() ||
                Number(yuanshiArr[i].orderStatusCountDown) ==
                new Date().getTime()
              ) {
                temporaryArr[i].orderStatusCountDown = "已超时";
              } else {
                let val =
                  Number(yuanshiArr[i].orderStatusCountDown) -
                  new Date().getTime();
                let newtime = new Date(val);
                let hour;
                if (newtime.getTime() > 3600000) {
                  hour = parseInt(newtime.getTime() / 3600000);
                  if (hour < 10) {
                    hour = "0" + hour;
                  }
                } else {
                  hour = "00";
                }
                let minute;
                if (newtime.getTime() > 60000) {
                  minute = newtime.getMinutes();
                  if (minute < 10) {
                    minute = "0" + minute;
                  }
                } else {
                  minute = "00";
                }
                let second = newtime.getSeconds();
                if (second < 10) {
                  second = "0" + second;
                }

                temporaryArr[i].orderStatusCountDown =
                  "还剩" + hour + ":" + minute + ":" + second;
              }
            }

            if (yuanshiArr[i].confirmTime) {
              let val2 =
                Number(yuanshiArr[i].confirmTime) - new Date().getTime();
              let newtime2 = new Date(val2);
              let hour2;
              if (newtime2.getTime() > 3600000) {
                hour2 = parseInt(newtime2.getTime() / 3600000);
                if (hour2 < 10) {
                  hour2 = "0" + hour2;
                }
              } else {
                hour2 = "00";
              }
              let minute2;
              if (newtime2.getTime() > 60000) {
                minute2 = newtime2.getMinutes();
                if (minute2 < 10) {
                  minute2 = "0" + minute2;
                }
              } else {
                minute2 = "00";
              }
              let second2 = newtime2.getSeconds();
              if (second2 < 10) {
                second2 = "0" + second2;
              }

              temporaryArr[i].confirmTime =
                hour2 + ":" + minute2 + ":" + second2;
            }
          }
          for (let i = 0; i < temporaryArr.length; i++) {
            this.tableData[i].orderStatusCountDown =
              temporaryArr[i].orderStatusCountDown;
            this.tableData[i].confirmTime = temporaryArr[i].confirmTime;
          }
        }, 1000);

        this.total = data.total;
      }

    },

    //客诉列表
    async getPageOrderListGuest() {
      if (this.eventStatusValue) {
        if (this.eventStatusValue == this.eventStatusOptianValue) {
          this.searchData.eventStatus = "";
        } else {
          this.searchData.eventStatus = this.eventStatusValue.join(",");
        }
      } else {
        this.searchData.eventStatus = "";
      }

      if (this.myAboutValue) {
        if (this.myAboutValue == this.myAboutOptianValue) {
          this.searchData.myAbout = "";
        } else {
          this.searchData.myAbout = this.myAboutValue.join(",");
        }
      } else {
        this.searchData.myAbout = "";
      }

      if (this.eventTypeValue) {
        if (this.eventTypeValue == this.eventTypeOptianValue) {
          this.searchData.eventType = "";
        } else {
          this.searchData.eventType = this.eventTypeValue.join(",");
        }
      } else {
        this.searchData.eventType = "";
      }
      this.multipleSelection = [];
      let { code, data } = await pageOrderListGuest_api(this.searchData);
      if (code == 0) {
        this.eventTableData = data.records;
        this.total = data.total;
      }
    },

    //二次确认订单/全部
    async getPageOrderListConfirm() {
      let checkedArr = JSON.parse(JSON.stringify(this.multipleSelection));
      this.multipleSelection = [];
      let { code, data } = await pageOrderListConfirm_api(this.searchData);
      if (code == 0) {
        data.records.forEach((item) => {
          if (item.isHotelPay == 0) {
            this.$set(item, "colorType", "color:#EE4D52");
          } else if (item.isHotelPay == 1) {
            this.$set(item, "colorType", "color:#33B93F");
          } else if (item.isHotelPay == 2) {
            this.$set(item, "colorType", "color:#256EF1");
          }
        });
        this.tableData = JSON.parse(JSON.stringify(data.records));

        if (this.timer) {
          //     console.log("销毁计时器");
          clearInterval(this.timer);
        }

        this.timer = setInterval(() => {
          let temporaryArr = JSON.parse(JSON.stringify(data.records));
          let yuanshiArr = JSON.parse(JSON.stringify(data.records));

          for (let i = 0; i < data.records.length; i++) {
            if (
              yuanshiArr[i].orderStatusCountDown == 0 ||
              yuanshiArr[i].orderStatusCountDown == "" ||
              yuanshiArr[i].orderStatusCountDown == null
            ) {
              temporaryArr[i].orderStatusCountDown = "";
            } else {
              if (
                Number(yuanshiArr[i].orderStatusCountDown) <
                new Date().getTime() ||
                Number(yuanshiArr[i].orderStatusCountDown) ==
                new Date().getTime()
              ) {
                temporaryArr[i].orderStatusCountDown = "已超时";
              } else {
                let val =
                  Number(yuanshiArr[i].orderStatusCountDown) -
                  new Date().getTime();
                let newtime = new Date(val);
                let hour;
                if (newtime.getTime() > 3600000) {
                  hour = parseInt(newtime.getTime() / 3600000);
                  if (hour < 10) {
                    hour = "0" + hour;
                  }
                } else {
                  hour = "00";
                }

                let minute;

                if (newtime.getTime() > 60000) {
                  minute = newtime.getMinutes();
                  if (minute < 10) {
                    minute = "0" + minute;
                  }
                } else {
                  minute = "00";
                }

                let second = newtime.getSeconds();
                if (second < 10) {
                  second = "0" + second;
                }

                temporaryArr[i].orderStatusCountDown =
                  "还剩" + hour + ":" + minute + ":" + second;
              }
            }

            if (yuanshiArr[i].confirmTime) {
              let val2 =
                Number(yuanshiArr[i].confirmTime) - new Date().getTime();
              let newtime2 = new Date(val2);
              let hour2;
              if (newtime2.getTime() > 3600000) {
                hour2 = parseInt(newtime2.getTime() / 3600000);
                if (hour2 < 10) {
                  hour2 = "0" + hour2;
                }
              } else {
                hour2 = "00";
              }
              let minute2;
              if (newtime2.getTime() > 60000) {
                minute2 = newtime2.getMinutes();
                if (minute2 < 10) {
                  minute2 = "0" + minute2;
                }
              } else {
                minute2 = "00";
              }
              let second2 = newtime2.getSeconds();
              if (second2 < 10) {
                second2 = "0" + second2;
              }

              temporaryArr[i].confirmTime =
                hour2 + ":" + minute2 + ":" + second2;
            }
          }
          for (let i = 0; i < temporaryArr.length; i++) {
            this.tableData[i].orderStatusCountDown =
              temporaryArr[i].orderStatusCountDown;
            this.tableData[i].confirmTime = temporaryArr[i].confirmTime;
          }

          // this.tableData = temporaryArr;
        }, 1000);
        this.total = data.total;
      }
    },

    //清空搜索栏
    cleanSearchData() {
      this.searchData = {
        page: 1,
        rows: 20,
        guestName: "",
        orderSn: "",
        agentOrderSn: "",
        cityName: "",
        hotelName: "",
        startTime: "",
        endTime: "",
        status: "",
        orderType: "",
        userIsPay: "",
        platform: "",
        orderTypeT: "",
        timeType: "1",
        goupId: "",
        orderType: "",
        quickSelected: "",
        myAbout: "",
        eventStatus: "",
        eventType: "",
      };
    },
    //清空组别
    cleanSearchDataT() {
      this.searchData.roleName = "";
      this.searchData.goupId = "";
      this.searchData.people = "";
      this.groupRoleList = [];
    },
    //搜索渠道选择
    setSearchPlatform(val) {
      this.searchData.platform = val;

      this.seachFn();
    },
    //
    setOrderType(val) {
      this.searchData.orderTypeT = val;

      this.seachFn();
    },
    //订单/获取渠道/二次确认
    async getChannel(val) {
      let { code, data } = await getChannel_api({ isPre: val });
      if (code == 0) {
        //  console.log(data);
        this.allChannelCount = data.all;
        this.ctripChannelCount = data.ctrip;
        this.filPigChannelCount = data.filPig;
        this.manualChannelCount = data.manual;
        this.meiTuanChannelCount = data.meiTuan;
        this.qunarChannelCount = data.qunar;
        this.sameCityChannelCount = data.sameCity;
        this.baiduChannelCount = data.baidu || 0;
        this.luoboChannelCount = data.luobo || 0;
        this.fctxChannelCount = data.fctx || 0;
      }
    },
    //订单/订单类型/二次确认
    async getOrderType(val) {
      let { code, data } = await getOrderType_api({ isPre: val });
      if (code == 0) {
        this.allTypeOrder = data.all;
        this.cuiTypeOrder = data.reminder;
        this.jiaoTypeOrder = data.handover;
        this.lanTypeOrder = data.blockOrder;
        this.kesuTypeOrder = data.keShu;
        this.kesuFiveTypeOrder = data.keShuFive5;
        this.confirmationTypeOrder = data.confirmation;
      }
    },
    //查询
    seachFn() {
      if (this.timer) {
        //如果定时器还在运行 或者直接关闭，不用判断
        clearInterval(this.timer); //关闭
      }
      this.searchData.page = 1;
      if (this.activeIndex == 1) {
        this.getPageOrderListPre();
        this.getChannel(1);
        this.getOrderType(1);
      } else if (this.activeIndex == 2) {
        this.getPageOrderListGuest();
        this.getChannel(3);
        this.getOrderType(3);
      } else if (this.activeIndex == 3) {
        this.getChannel(2);
        this.getOrderType(2);
        this.searchData.isSecende = 2;
        this.getPageOrderListConfirm();
      } else if (this.activeIndex == 4) {
        this.getChannel(0);
        this.getOrderType(0);
        this.searchData.isSecende = 1;
        this.getPageOrderListConfirm();
      } else if (this.activeIndex == 5) {
        // this.getChannel(0);
        // this.getOrderType(0);
        //  this.searchData.isSecende = 3;
        this.getPageOrderListPreElong();
      }
    },
    //订单分页
    handleCurrentChange(val) {
      this.searchData.page = val;

      if (this.activeIndex == 1) {
        this.getPageOrderListPre();
      } else if (this.activeIndex == 2) {
        this.getPageOrderListGuest();
      } else if (this.activeIndex == 3) {
        this.searchData.isSecende = 2;
        this.getPageOrderListConfirm();
      } else if (this.activeIndex == 4) {
        this.searchData.isSecende = 1;
        this.getPageOrderListConfirm();
      } else if (this.activeIndex == 5) {
        // this.searchData.isSecende = 3;
        this.getPageOrderListPreElong();
      }
    },
    //格式化时间
    initTime(val) {
      let value = new Date(val);
      let hour = value.getHours();
      if (hour < 10) {
        hour = "0" + hour;
      }
      let minute = value.getMinutes();
      if (minute < 10) {
        minute = "0" + minute;
      }
      let second = value.getSeconds();
      if (second < 10) {
        second = "0" + second;
      }

      return hour + ":" + minute + ":" + second;
    },
    //锁定/解锁订单
    async setOrderLock(id, val) {
      let { code, data } = await orderLock_api({
        orderId: id,
        isLock: val,
      });
      if (code == 0) {
        this.$message.success(data ? data : "成功");
        this.seachFn();
      }
    },
    //打开备注模态框
    openOrderLogDia(id) {
      this.$refs.addorderlog.open(id);
    },
    //打开二次确认里面的确认模态框
    openOrderIsInterceptStateDia(id) {
      this.$refs.interceptstatedia.open(id);
    },
    //点击接受/我知道了
    setOrderAccept(id) {
      this.$confirm("接受这个订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.setOrderAcceptFn(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消此操作",
          });
        });
    },
    openOrderAccept(id, type) {
      this.$refs.orderaccept.open(id, type);
    },
    async setOrderAcceptFn(id) {
      let { code, data } = await orderAccept_api({
        orderId: id,
      });
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.seachFn();
      }
    },
    //拒绝按钮
    setOrderRefuse(id) {
      this.$confirm("拒绝这个订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.setOrderRefuseFn(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消此操作",
          });
        });
    },
    async setOrderRefuseFn(id) {
      let { code, data } = await orderRefuse_api({ orderId: id });
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.seachFn();
      }
    },
    //同意取消
    setAgreeCancel(row) {
      console.log(row);
      if (row.platform == 6) {
        this.$refs.agreebaiducancel.open(row.orderId);
      } else {
        this.$confirm("同意取消, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.setAgreeCancelFn(row.orderId);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消此操作",
            });
          });
      }
    },
    async setAgreeCancelFn(id) {
      let { code, data } = await agreeCancel_api({ orderId: id });
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.seachFn();
      }
    },
    //拒绝取消
    async setUnAgreeCancel(id) {
      this.$confirm("拒绝取消, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.setUnAgreeCancelFn(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消此操作",
          });
        });
    },
    async setUnAgreeCancelFn(id) {
      let { code, data } = await notAgreeCancel_api({ orderId: id });
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.seachFn();
      }
    },
    //打开发单模态框
    openOrderInfoDia(data) {
      this.$refs.orderinfo.open(data);
    },
    //跳转详情页面
    openOrderDetailInfoDia(id) {
      // this.$refs.orderdetailsinfo.open(id)
      this.$router.push({
        path: "/orderdetailsinfo",
        query: {
          id: id,
        },
      });
    },
    //跳转手工单页面
    gotoManuaOrder() {
      this.$router.push({
        path: "/manualorder",
      });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    //猩房房发布订单页面
    gotoReleaseOrder() {
      if (this._isMobile()) {
        this.$router.push({
          path: "/mobilereleaseOrder",
        });
      } else {
        this.$router.push({
          path: "/releaseOrder",
        });
      }
    },
    //打开转至模态框
    openChangeOrderTypeDia(id) {
      this.$refs.changeordertypedia.open(id);
    },
    openChangeOrderTypeDiab() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning("请选择需要转的订单");
      } else {
        let setArr = [];
        this.multipleSelection.forEach((item) => {
          setArr.push(item.orderId);
        });
        this.$refs.changeordertypedia.open(setArr.join(","));
      }
    },

    //客诉
    //事件状态多选
    changeEventStatus(value) {
      let checkedCount =
        Number(value.length) + Number(this.myAboutValue.length);

      this.isAllEventStatus = checkedCount === 5;
      this.isIndeterEventStatus = checkedCount > 0 && checkedCount < 5;
    },
    changeMyAbout(value) {
      let checkedCount =
        Number(value.length) + Number(this.eventStatusValue.length);

      this.isAllEventStatus = checkedCount === 5;
      this.isIndeterEventStatus = checkedCount > 0 && checkedCount < 5;
    },
    //事件类型全选
    checkAllEventStatus(val) {
      this.eventStatusValue = val ? this.eventStatusOptianValue : [];
      this.myAboutValue = val ? this.myAboutOptianValue : [];
      this.isIndeterEventStatus = false;
    },

    changeEventType(value) {
      let checkedCount = value.length;
      this.isAllEventType = checkedCount === this.eventTypeOptian.length;
      this.isIndeterEventType =
        checkedCount > 0 && checkedCount < this.eventTypeOptian.length;
    },

    checkAllEventType(val) {
      this.eventTypeValue = val ? this.eventTypeOptianValue : [];
      this.isIndeterEventType = false;
    },
    //客诉订单跟进按钮
    setEventTrail(id) {
      this.$confirm("跟进这个客诉订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.setEventTrailFn(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消此操作",
          });
        });
    },
    async setEventTrailFn(id) {
      let { code, data } = await eventTrail_api({
        orderId: id,
      });
      if (code == 0) {
        this.$message.success(data ? data : "跟进成功");
        this.seachFn();
      }
    },
    //客诉订单回复
    openEventOverDia(row) {
      this.$refs.eventover.open(row.orderId, row.orderSn, row.evenType);
    },
    //客诉订单转人
    setEventPerson(id) {
      this.$refs.changeeventperson.open(id);
    },
    //订单/二次确认列表快速选中(今日新订)
    async getPageOrderListConfirmSelected() {
      let { code, data } = await pageOrderListConfirmSelected_api({});
      if (code == 0) {
        this.todayNewOrder = data.todayNewOrder;
        this.todayNewComeIn = data.todayNewComeIn;
        this.etcComeIn = data.etcComeIn;
      }
    },
    //导出
    orderExport() {
      if (this.activeIndex == 1) {
        this.excelUrl =
          "http://47.108.228.93:8080/otaHotel/order/pageOrderListPreExcel?token=" +
          this.token +
          "&page=1" +
          "&rows=999999" +
          "&roleName=" +
          this.searchData.roleName +
          "&goupId=" +
          this.searchData.goupId +
          "&people=" +
          this.searchData.people +
          "&guestName=" +
          this.searchData.guestName +
          "&orderSn=" +
          this.searchData.orderSn +
          "&agentOrderSn=" +
          this.searchData.agentOrderSn +
          "&hotelName=" +
          this.searchData.hotelName +
          "&timeType=" +
          this.searchData.timeType +
          "&startTime=" +
          this.searchData.startTime +
          "&endTime=" +
          this.searchData.endTime +
          "&status=" +
          this.searchData.status +
          "&userIsPay=" +
          this.searchData.userIsPay +
          "&platform=" +
          this.searchData.platform +
          "&orderTypeT=" +
          this.searchData.orderTypeT +
          "&orderType=" +
          this.searchData.orderType;
      } else if (this.activeIndex == 2) {
        this.excelUrl =
          "http://47.108.228.93:8080/otaHotel/order/pageOrderListGuestExcel?token=" +
          this.token +
          "&page=1" +
          "&rows=999999" +
          "&roleName=" +
          this.searchData.roleName +
          "&goupId=" +
          this.searchData.goupId +
          "&people=" +
          this.searchData.people +
          "&guestName=" +
          this.searchData.guestName +
          "&orderSn=" +
          this.searchData.orderSn +
          "&agentOrderSn=" +
          this.searchData.agentOrderSn +
          "&hotelName=" +
          this.searchData.hotelName +
          "&timeType=" +
          this.searchData.timeType +
          "&startTime=" +
          this.searchData.startTime +
          "&endTime=" +
          this.searchData.endTime +
          "&status=" +
          this.searchData.status +
          "&userIsPay=" +
          this.searchData.userIsPay +
          "&platform=" +
          this.searchData.platform +
          "&orderTypeT=" +
          this.searchData.orderTypeT +
          "&orderType=" +
          this.searchData.orderType +
          "&eventStatus=" +
          this.searchData.eventStatus +
          "&myAbout=" +
          this.searchData.myAbout +
          "&eventType=" +
          this.searchData.eventType;
      } else if (this.activeIndex == 3) {
        this.excelUrl =
          "http://47.108.228.93:8080/otaHotel/order/pageOrderListConfirmExcel?token=" +
          this.token +
          "&page=1" +
          "&rows=999999" +
          "&isSecende=2" +
          "&roleName=" +
          this.searchData.roleName +
          "&goupId=" +
          this.searchData.goupId +
          "&people=" +
          this.searchData.people +
          "&guestName=" +
          this.searchData.guestName +
          "&orderSn=" +
          this.searchData.orderSn +
          "&agentOrderSn=" +
          this.searchData.agentOrderSn +
          "&hotelName=" +
          this.searchData.hotelName +
          "&timeType=" +
          this.searchData.timeType +
          "&startTime=" +
          this.searchData.startTime +
          "&endTime=" +
          this.searchData.endTime +
          "&status=" +
          this.searchData.status +
          "&userIsPay=" +
          this.searchData.userIsPay +
          "&platform=" +
          this.searchData.platform +
          "&orderTypeT=" +
          this.searchData.orderTypeT +
          "&orderType=" +
          this.searchData.orderType;
      } else if (this.activeIndex == 4) {
        this.excelUrl =
          "http://47.108.228.93:8080/otaHotel/order/pageOrderListConfirmExcel?token=" +
          this.token +
          "&page=1" +
          "&rows=999999" +
          "&isSecende=1" +
          "&roleName=" +
          this.searchData.roleName +
          "&goupId=" +
          this.searchData.goupId +
          "&people=" +
          this.searchData.people +
          "&guestName=" +
          this.searchData.guestName +
          "&orderSn=" +
          this.searchData.orderSn +
          "&agentOrderSn=" +
          this.searchData.agentOrderSn +
          "&hotelName=" +
          this.searchData.hotelName +
          "&timeType=" +
          this.searchData.timeType +
          "&startTime=" +
          this.searchData.startTime +
          "&endTime=" +
          this.searchData.endTime +
          "&status=" +
          this.searchData.status +
          "&userIsPay=" +
          this.searchData.userIsPay +
          "&platform=" +
          this.searchData.platform +
          "&orderTypeT=" +
          this.searchData.orderTypeT +
          "&orderType=" +
          this.searchData.orderType +
          "&quickSelected=" +
          this.searchData.quickSelected;
      }
    },
    //打开推送确认号模态框
    openConfirm(id) {
      this.$refs.confirmdia.open(id);
    },
    //表单的选择
    handleSelectionChange(val) {
      //  console.log("列表选择", val);
      this.multipleSelection = val;
    },

    //跳转房型管理
    gotoRoom(item) {
      console.log(item);
      local.set("roomPageHotelId", item.hotelSonId);
      this.$router.push({
        path: "/room",
        // query: {
        //   id: item.roomId,
        // },
      });
    },
    //事件类型格式化
    formatEvetType(row, column, cellValue) {
      if (cellValue == 1) {
        return "到店无房";
      } else if (cellValue == 2) {
        return "到店查无";
      } else if (cellValue == 3) {
        return "未到店查无";
      } else if (cellValue == 4) {
        return "确认后满房";
      } else if (cellValue == 5) {
        return "确认后涨价";
      } else if (cellValue == 6) {
        return "确认前满房";
      } else if (cellValue == 7) {
        return "确认前涨价";
      } else if (cellValue == 8) {
        return "协商取消";
      }
    },
    openSetProfitPrice(row) {
      console.log(row);
      this.$refs.setprofitPrice.open(row);
    },
    setIsConfirm(id) {
      this.$confirm("确认收到？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.setIsConfirmFn(id);
      });
    },
    async setIsConfirmFn() {
      let { code, data } = await setIsConfirm_api({
        orderId: id,
      });
      if (code == 0) {
        this.$message.success("已收到");
      }
    },
    openSetOrderState(row) {
      this.$refs.setorderstate.open(row);
    },
    beforeDestroy() {
      if (this.timer) {
        //如果定时器还在运行 或者直接关闭，不用判断
        clearInterval(this.timer); //关闭
      }
      if (this.timerTwo) {
        //如果定时器还在运行 或者直接关闭，不用判断
        clearInterval(this.timerTwo); //关闭
      }
    },
    //猩房房全部取消
    cancelAllForXff(row) {
      this.$confirm("是否完全取消此订单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.cancelAllForXffFn(row);
      });
    },

    async cancelAllForXffFn(row) {
      let { code, data } = await cancelAllForXff_api({
        id: row.orderId,
      });
      if (code == 0) {
        this.$message.success(data ? data : "取消成功");
        this.seachFn();
      }
    },

    openCancelPartForXffOrder(row) {
      this.$refs.cancelpartforxff.open(row);
    },
    openManualRefundDia(row) {
      this.$refs.manualrefund.open(row.orderId);
    }
  },
};
</script>

<style lang="less" scoped>
.order>.orderHead {
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  font-size: 12px;

  /deep/ .el-menu--horizontal>.el-menu-item {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    width: 140px;
    margin-right: 30px;
    text-align: center;
  }

  /deep/ .el-menu--horizontal>.el-menu-item.is-active {
    font-weight: bold;
    color: #256def;
  }
}

.orderSearchBox {
  font-size: 14px;

  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
  padding-bottom: 30px;

  /deep/ .el-checkbox-button__inner {
    border: 1px solid #dcdfe6;
    padding: 7px 20px;
    font-size: 12px;
    border-radius: 4px;
  }

  .el-checkbox-button--mini .el-checkbox-button__inner /deep/ .el-checkbox-button.is-checked .el-checkbox-button__inner {
    border-color: #409eff;
  }

  /deep/ .el-radio-button__inner {
    border: 1px solid #dcdfe6;
    padding: 7px 20px;
    font-size: 12px;
  }

  // /deep/ .el-radio-button.is-checked .el-radio-button__inner {
  //   border-color: #409eff;
  // }
  /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
    border-color: #409eff;
  }
}

.relativeself {
  position: relative;
  top: 4px;
}

.inlinebolckBox {
  display: inline-block;
  margin-right: 30px;
}

.paddingtop20 {
  padding-top: 20px;
  padding-left: 20px;
}

.marginRight14 {
  margin-right: 14px;
}

.orderContentBigBox {
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
  margin-top: 20px;
  padding-bottom: 50px;
}

.searchBoxTwo {
  font-size: 14px;
  font-family: Microsoft YaHei;
  padding: 7px 10px;
}

.marginright20 {
  margin-right: 20px;
}

.marginright10 {
  margin-right: 10px;
}

.rightcolBox {
  height: 40px;
  line-height: 40px;
  text-align: right;
}

.checkedPageText {
  color: #256ef1;
}

.pointer {
  cursor: pointer;
}

.tableHead {
  height: 64px;
  background: #ebf1fa;
  margin-top: 23px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.orderOneTableBox {
  /deep/ .el-table {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  /deep/ .el-table thead {
    height: 64px;
    background: #ebf1fa;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }

  /deep/ .el-table th.el-table__cell {
    background: #ebf1fa;
  }

  /deep/ .el-divider--horizontal {
    margin: 10px 0;
  }

  /deep/ .el-table_1_column_7 {
    border-left: 1px solid #ebf1fa;
  }

  /deep/ .el-table_1_column_12 {
    padding: 3px 0;
  }

  /deep/ .el-table_1_column_12>.cell {
    padding: 0 5px;
  }

  /deep/ .el-table_1_column_8>.cell {
    padding: 0;
  }
}

.cuidanBox {
  padding: 0 8px;
  height: 18px;
  line-height: 18px;
  text-align: center;

  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
}

.isReservedBox {
  display: inline-block;
  height: 16px;
  line-height: 16px;
  padding: 0 3px;
  background: #33b93f;
  border-radius: 2px;
  color: #ffffff;
  margin-right: 2px;
}

.jieshouandtongyi {
  height: 16px;
  background: #256def;
  border-radius: 4px;
  color: #ffffff;
  line-height: 16px;
  cursor: pointer;
  // vertical-align:top
}

.jujueBox {
  height: 16px;
  border: 1px solid #256def;
  border-radius: 4px;
  line-height: 16px;
  width: 36px;
  margin-left: 12px;
  cursor: pointer;
}

.eventStatusBox {
  width: 54px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 2px;
  color: #ffffff;
}

.eventEditBtn {
  height: 16px;
  line-height: 16px;
  background: #256def;
  border-radius: 4px;
  color: #ffffff;
  display: inline-block;
  text-align: center;
  width: 50%;
}

.eventEditBtnTwo {
  display: inline-block;
  text-align: center;
  width: 50%;
}

.roomPagin {
  text-align: right;
  display: inline-block;
}

/deep/.el-input__inner,
/deep/.el-range-input {
  // background: #ebeff4;
  background: #f6f8fb;
}

.xingfangfang {
  height: 40px;
  line-height: 40px;
}
</style>